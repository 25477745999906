import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Being More Technology Focused`}</h1>
    <div style={{
      "position": "relative",
      "paddingBottom": "56.25%",
      "height": "0"
    }}><iframe src="https://www.loom.com/embed/c9a3d398d6ef4387842aecefde6d1b8c" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></div>
    <p>{`I've always been a technology fanatic. If you've ever had me in a class, whether it was in elementary, middle, or high school, you know that I love to make people's lives easier with computers. Whether it be creating an app to take Zoom attendance easier or showing teachers how to do cool spreadsheet formulas, I find joy in making people's lives more tech-focused.`}</p>
    <p>{`It's inevitable that next year will incorporate some sort of technology aspect. Whether it be having a similar hybrid situation like this year or being at school five days a week, but not being able to have the entire school pack the gym for rallies like we used to, we need to find ways to make our school more advanced in software. We can purchase software like Zoom Webinar to have 2000 people join a call live, with the chat on/videos off. This would also let us run live rallies, instead of it being pre-recorded.`}</p>
    <p>{`Schools like Portola and University High have great apps: PortolApp and UniConnect. My goal for next year is to lead the development of the app: MyVaquero. This would incorporate features like showing the schedule, notifying you about special events, and most importantly: meeting new friends at Irvine High. In my freshman year at Irvine, I met most of my friends through clubs or classes. That was, obviously, not as possible this year so I think there should be more digital ways of doing it. In May 2020, Irvine High ASB did a fantastic penpal system, and to this day my penpal and I occasionally ask each other questions about our days. Incorporating that into the app would be a great idea and having a directory of Irvine High student's social media. If someone doesn't have social media, then they can chat with people on the app directly.`}</p>
    <p>{`Another way we can incorporate technology on campus by adopting communication systems. What if we had a school-wide Slack or Discord server? Just an idea to think about more...`}</p>
    <p>{`Overall, I think we should make our school more technology focused, incorporating new ideas and using cell phones, computers, and tablets to our advantage. Doing more than just Zoom and Canvas by adding easy to use collaboration and communication software into the mix.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      